import React, { useContext } from 'react';
import EntryScreen from '../screens/EntryScreen';
import StepOne from '../screens/StepOne';
import StepThree from '../screens/StepThree';
import StepTwo from '../screens/StepTwo';
import ThankyouScreen from '../screens/ThankyouScreen';
import { Context } from './Context';

const StartScreen = () => {
    const [context, setContext] = useContext(Context);
    return (
        <div>
            {context.step === "entry" && <EntryScreen />}
            {context.step === "step1" && <StepOne />}
            {context.step === "step2" && <StepTwo />}
            {context.step === "step3" && <StepThree />}
            {context.step === "thankyou" && <ThankyouScreen />}
        </div>
    );
};

export default StartScreen;