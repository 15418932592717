import React, { useState, useEffect } from 'react';
import icon1 from '../assets/images/icon1.svg';
import icon2 from '../assets/images/icon2.svg';
import { tabLabels } from "../components/ScreenLabels";
import { stepOneLabels, commonLabels } from "../components/ScreenLabels";
import {faCircleExclamation, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const BlurBackground = () => {
    const [currentTab, setCurrentTab] = useState(1);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
    }, []);

    return (
        <>
            <div className='content'>
                <div className='tab-container'>
                    <div className='tabs'>
                        {tabLabels.map((tab, i) =>
                            <button style={{ cursor: "auto" }} disabled={currentTab == `${tab.id}`} key={i} id={tab.id}>{tab.title}</button>
                        )}
                    </div>
                    <div className="step-main-container">
                        <div className="row bg-image">
                            <div className="col-sm form-left">
                                <div className="tab-header">{stepOneLabels.header}</div>
                                <div className='form-section'>
                                    <div className='form-label'>{stepOneLabels.compName}<span className='asterisk'>*</span></div>
                                    <input type="text" className="form-control" />
                                </div>
                                <div className='form-section'>
                                    <div className='form-label'>{stepOneLabels.regNumAvailability}</div>
                                    <div className="form-control d-flex">
                                        <div className='pr-4'><input type="radio" id={stepOneLabels.radioButtonLabel1} name="reg_no" value={stepOneLabels.radioButtonLabel1} />
                                            <span className='radio-element pl-2' htmlFor={stepOneLabels.radioButtonLabel1}>{stepOneLabels.radioButtonLabel1}</span></div>
                                        <div><input type="radio" id={stepOneLabels.radioButtonLabel2} name="reg_no" value={stepOneLabels.radioButtonLabel2} />
                                            <span className='radio-element pl-2' htmlFor={stepOneLabels.radioButtonLabel2}>{stepOneLabels.radioButtonLabel2}</span></div>
                                    </div>
                                </div>
                                <div className='form-section'>
                                    <div className='form-label'>{stepOneLabels.searchAdd}<span className='asterisk'>*</span></div>
                                    <div className="autocomplete-element">
                                        <div className='form-label'>
                                            <span className="address-manual-span">{stepOneLabels.manuallEnter}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='form-section'>
                                    <div className='form-label'>
                                        {stepOneLabels.ukNum}
                                        <span className='asterisk'>*</span>
                                        <span className='tooltip-icon'>
                                            {/* <i className="fa fa-exclamation-circle"></i> */}
                                            <FontAwesomeIcon icon={faCircleExclamation} />
                                            <span className="tooltiptext">{stepOneLabels.ukNumTooltip}</span>
                                        </span></div>
                                    <input type="text" className="form-control" />
                                </div>
                                <div className='form-section'>
                                    <div className='form-label'>{stepOneLabels.industry}<span className='asterisk'>*</span></div>
                                    <select name="industry" id="industry" className="form-control">
                                        <option value=""></option>
                                    </select>
                                </div>
                                <div className='form-section'>
                                    <div className='form-label'>{stepOneLabels.telNum}<span className='asterisk'>*</span></div>
                                    <input type="text" className="form-control" />
                                </div>
                                <div className='form-section'>
                                    <div className='form-label'>{stepOneLabels.schemeLimit}<span className='asterisk'>*</span>
                                        <span className='tooltip-icon'>
                                            {/* <i className="fa fa-exclamation-circle"></i> */}
                                            <FontAwesomeIcon icon={faCircleExclamation} />
                                            <span className="tooltiptext">{stepOneLabels.schemelimitTooltip}</span>
                                        </span></div>
                                    <input type="text" className="form-control" />
                                </div>
                                <div className='form-section'>
                                    <div className='form-label'>{stepOneLabels.hearAbout}<span className='asterisk'>*</span></div>
                                    <select name="hearaboutus" id="hearaboutus" className='form-control'>
                                        <option value=""></option>
                                    </select>
                                </div>
                                <button className='step1-next-button'>
                                    {commonLabels.nextButton}
                                    {/* <i className="fa fa-arrow-right"></i> */}
                                    <FontAwesomeIcon icon={faArrowRight} />
                                </button>
                            </div>                         
                            <div className="col-sm form-right">
                                <div className='right-content-main-step1' style={{marginTop: "100px"}}>
                                    <div className='right-content-header'>{stepOneLabels.content1}
                                    </div>
                                    <div className='right-content-text'>{stepOneLabels.content2}
                                    </div>
                                    <div className='row row-step1'>
                                        <div className='col-sm-2 col-icon'>
                                            <img src={icon1} alt="icon1" />
                                        </div>
                                        <div className='col-sm col-text'>{stepOneLabels.content3}
                                        </div>
                                    </div>
                                    <div className='row row-step2'>
                                        <div className='col-sm-2 col-icon'>
                                            <img src={icon2} alt="icon2" />
                                        </div>
                                        <div className='col-sm col-text'>{stepOneLabels.content4}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlurBackground