export const experianAddressSearchAPIURL = "https://api.experianaperture.io/address/search/v1";
export  const experianDetailsFromTablePostData = {"postdataForExperianAPI": "string"};


/** Change 
 * 'environment'
 * 'hireAgreementEnvironmentURL' 
 * 'homePageLink'
 * 'signinRedirectLink'
 * while changing environment 
 * */

/** DEV*/
// const environment = "DEV";
// export const docusignEnvironmentURL = "https://demo.docusign.net";
// export const homePageLink = "https://hubil-web-employer-v1-dev.azurewebsites.net/";
// export const signinRedirectLink ="https://halfordsb2bportal.powerappsportals.com/SignIn?returnUrl=%2F";
// export const GtmId= "GTM-5NKBDMSL";
// export const  Auth= "iOuAVvZC41-zun_BnArBYg";
// export const  Preview= "env-3";

/** QA */
// const environment = "QA";
// export const docusignEnvironmentURL = "https://demo.docusign.net";
// export const homePageLink = "https://hubil-web-employer-v1-qa.azurewebsites.net/";
// export const signinRedirectLink ="https://halfordsb2bportalqa.powerappsportals.com/SignIn?returnUrl=%2F";
// export const GtmId= "GTM-5NKBDMSL";
// export const  Auth= "b87klHnq_n_Cj8dVAtnT9A";
// export const  Preview= "env-4";



/** PROD */
const environment = "PROD";
export const docusignEnvironmentURL = "https://eu.docusign.net";
export const homePageLink = "https://hubil-web-employer-v1-prd.azurewebsites.net/";
export const signinRedirectLink ="https://www.halfordsforbusiness.com/SignIn?returnUrl=%2F";
export const GtmId="GTM-5NKBDMSL";
export const  Auth= "";
export const  Preview= "";


let baseURL = '';
if(environment==="DEV"){
    baseURL="https://dev.api.halfords.co.uk/SalarysacrificeLogicApp/";
}else if(environment==="QA"){
    baseURL="https://qa.api.halfords.co.uk/SalarysacrificeLogicApp/";
}else if(environment==="PROD"){
    baseURL="https://api.halfords.co.uk/SalarysacrificeLogicApp/";
}

export const industryListAPIURL = baseURL + "DV_IndustryType_Orch_PR";
export const compRegNumberValidationAPIURl = baseURL + "CreditSafe_CheckCompany_PB";
export const reqAssistanceAPIURL = baseURL + "DV_RequiredAssistance_EMPReg_Orch_PR";
export const submitAPIURL =  baseURL + "DV_EmployerRegistration_Orch_PR";
export const errorMessageAPIURL =   baseURL + "DV_empApp_errorMsg_PR";
export const hireAgreementAPIUrl =   baseURL + "DV_empHireAgreeIDsDocuSign_orch_pr";
export const experianDetailsFromTableAPIURL=  baseURL + "DV_experianAuth_orch_pr";
export const TRACKING_ID= "G-LS7T16V6MD";

