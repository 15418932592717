export const commonLabels = {
    nextButton: "Next",
    backButton: "Back",
    submitButton: "Submit",
    registerButton: "Register Now",
    emailMaxLen: "60",
    mobileNumberMinLen: "9",
    mobileNumberMaxLen: "20",
    networkMsg: "Sorry, we are unable to connect to the network. Please check your internet connection.",
    okButtonText: "Ok"
}

export const entryScreenLabels = {
    introTextLine1: "Are you a decision maker for your business wishing to register your business for Cycle2Work?",
    introTextLine2: "Use our three-step application form to register your business in minutes!",
    introTextLine3: "What information will I need?",
    introTextLine4: [
        { data: "Business details" },
        { data: "Your desired scheme limit" },
        { data: "Number of PAYE employees" },
        { data: "Your email and an email for invoices" }
    ],
}

export const stepOneLabels = {
    header: "Company Details",
    compName: "Company name",
    regNumAvailability: "Do you have a company registration number?",
    radioButtonLabel1: "YES",
    radioButtonLabel2: "NO",
    compRegNum: "Enter company registration number",
    searchAdd: "Search for address (postcode or street)",
    searchButtonLabel: "SEARCH",
    manuallEnter: "Or enter it manually",
    ukNum: "Number of UK PAYE employees",
    ukNumTooltip: "Only employees that are paid through Pay As You Earn (PAYE) system are eligible to benefit from the scheme. Please only enter the number of employees that are paid through PAYE.",
    industry: "Select Industry",
    telNum: "Company telephone number",
    schemeLimit: "Cycle2Work Scheme Limit",
    schemelimitTooltip: "Choose how much your employees can spend on Cycle2Work. Traditionally the maximum value was capped at £1,000, but thanks to the Cycle2Work Alliance employers can set their own limit at any value they see fit.",
    hearAbout: "How did you hear about Cycle2Work",
    content1: "Join the UK’s leading Cycle2Work provider.",
    content2: "Before you begin, please be sure:",
    content3: "Your staff are paid via Pay As You Earn (PAYE)",
    content4: "You are a decision maker with the right to enter into a legal agreement on behalf of your organisation",
    // content5: "If you have any questions or you require assistance, please email our team.",
    chooseManually: "Choose you address or enter here to add manually",
    enterManually: "Enter address manually",
    cancelTooltip:'To choose your address, click here.',
    addLine1: "Address Line 1",
    addLine2: "Address Line 2",
    addLine3: "Address Line 3",
    town: "Town/City",
    postcode: "Post Code",
    country: "Country",
    compNameMaxLeg: "80",
    compRegNumMaxLen: "15",
    countryArray: [{
        option: "United Kingdom",
    },
    {
        option: "Republic of Ireland"
    }  
    ],
    addressLine1MaxLength: 30,
    addressLine2MaxLength: 30,
    addressTownMaxLength: 20,
    addressPostCodeMaxLength: 10,
}

export const stepTwoLabels = {
    header: "Main Contact",
    fname: "First name",
    sname: "Surname",
    jobTitle: "Job title",
    phNum: "Phone number",
    emailAdd: "Email address",
    confirmEmailAdd: "Confirm email address",
    invoiceEmail: "Email for invoices",
    invoicemailTooltip: "If you do not nominate an alternative email for invoices, all invoices related to Cycle2Work will be sent to the main contact email address.",
    confirmInvoiceEmail: "Confirm email for invoices",
    content1: "Almost there!",
    content2: "As the main contact, you will be responsible for:",
    content3: "REVIEWING REQUESTS",
    content4: "APPROVING APPLICATIONS",
    content5: "You will also have access to a full range of scheme administration tools via your employer portal.",
    content6: "Subject to a company criteria check your account will be assigned as either a pro-forma or credit account. You will receive confirmation of this once your scheme has been setup.",
    content7: "Accounts come with Scheme Protection as standard. Our service has a modest admin charge. Refer to the Cycle2Work agreement on the next page for details.",
   
}

export const stepThreeLabels = {
    header1: "Confirm and Agree to",
    header2: "Cycle2work Terms",
    downloadButton: "DOWNLOAD AGREEMENT",
    viewButton: "VIEW IN NEW WINDOW",
    confirmText: "I confirm I have read and agree to terms and conditions.",
    linkLabel: "If you have any questions or you require assistance,",
    linkText: "please click here.",
    content1: "Thank you for sharing the details.",
    content2: "Are you ready to sign the Cycle2Work Terms Agreement?",
    // content3: "If you would like a member of our team to reach out to you and discuss your requirements click below.",
    content3: "We'll be in touch.",
    content4: "Your details have been forwarded. A member of our team will be in touch shortly to discuss your requirements.",
    content5: "Sorry, something seems to have gone wrong.",
    content6: " Kindly Try again.",
    reqAssistanceButton: "REQUEST ASSISTANCE",
    confirmButton: "CONFIRM",
}

export const thankYouScreenLabels = {
    success: "Company Created Successfully",
    already: "Company Already Registered",
    wrong: "Sorry, something seems to have gone wrong. Kindly Try again.",
    linktext: "Please click here to Sign In",
    link: "https://halfordsb2bportal.powerappsportals.com/SignIn?returnUrl=%2F",
    content1: "Thank you for registering your business for Cycle2Work!",
    content2: "We have received your application and will be in touch with you in the next 24 hours to provide next steps.",
    content3: "A copy of your agreement has been sent to the email provided.",
    content4: "Kind Regards,",
    content5: "The Halfords For Business Team.",
}

export const tabLabels = [
    {
        id: 1,
        title: '1. Company Details',
        route: "step1"
      },
      {
        id: 2,
        title: '2. Confirm Main Contact',
        route: "step2"
      },
      {
        id: 3,
        title: '3. Sign Agreement',
        route: "step3"
      }
]

export const validationMsg = {
    validRegNo: "Please enter a valid UK Company registration number.",
    valiCompId: "Valid CompanyID",
    compName: "Please make sure you enter a valid company name",
    schemeLimit: "Please enter a valid scheme limit",
}
