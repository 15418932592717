import './App.css';
import { useState } from 'react';
import { Context } from './components/Context';
import StartScreen from './components/StartScreen';
import ReactGA from "react-ga4";
import { TRACKING_ID,GtmId,Auth,Preview } from './components/ApiUrls';
import TagManager from 'react-gtm-module';




ReactGA.initialize(TRACKING_ID);


//for Dev and QA
// const tagManagerArgs = {
//   gtmId: GtmId,
//   auth: Auth,
//   preview: Preview
// }


//For Prod
const tagManagerArgs = {
  gtmId: GtmId
}

TagManager.initialize(tagManagerArgs)



function App() {
    const [context, setContext] =useState({step:"entry"});
  return (
    <Context.Provider value={[context, setContext]}>
    <div className="App">
      <StartScreen />
    </div>
    </Context.Provider>
  );
}

export default App;
