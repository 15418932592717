import React, { useState, useContext, useEffect } from 'react';
import { thankYouScreenLabels } from '../components/ScreenLabels';
import { Context } from '../components/Context';
import BlurBackground from './BlurBackground';
import cycleToWorkLogo from '../assets/images/cycleToWorkLogo.jpg';
import { signinRedirectLink } from '../components/ApiUrls';
const ThankyouScreen = () => {
  const [context, setContext] = useContext(Context);

  const redirect = (e) => {
    e.preventDefault();
    // window.top.location.href=thankYouScreenLabels.link;
    // document.referrer?window.top.location.href=thankYouScreenLabels.link:window.location.href=thankYouScreenLabels.link;
    // window.location.href=signinRedirectLink;
    if (document.referrer) {
      window.top.location.href = signinRedirectLink;
    } else {
      window.location.href = signinRedirectLink;
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <>
      <div className='tabscreen-blur'>
        <BlurBackground />
      </div>
      <div className='entrypage-content thankyoupage-top'>
        <div>
          <img src={cycleToWorkLogo} alt="cycleToWorkLogo" className='blur-bg-logo' />

        </div>
        <div className='card card-overlay'>
          {context.status === 1 && <div>
            <div className="card-overlay-header">
              {thankYouScreenLabels.content1}
            </div>
            <div className="card-overlay-text">
              {thankYouScreenLabels.content2}
            </div>
            <div className="card-overlay-text">
              {thankYouScreenLabels.content3}
            </div>
            <div className="card-overlay-text">
              {thankYouScreenLabels.content4}<br />
              {thankYouScreenLabels.content5}
            </div>
          </div>}
          {context.status === 2 && <div>
            <div className="card-overlay-header">
              {thankYouScreenLabels.already}<br />
            </div>
            <div className="card-overlay-text">
              {/* <a href={thankYouScreenLabels.link} target="blank">{thankYouScreenLabels.linktext}</a> */}
              <a href='#' onClick={(e) => { redirect(e) }}>{thankYouScreenLabels.linktext}</a>
            </div>
            <div className="card-overlay-text">
              {thankYouScreenLabels.content4}<br />
              {thankYouScreenLabels.content5}
            </div>
          </div>}
          {context.status === 3 && <div>
            <div className="card-overlay-header">
              {thankYouScreenLabels.wrong}
            </div>
            <div className="card-overlay-text">
              {thankYouScreenLabels.content4}<br />
              {thankYouScreenLabels.content5}
            </div>
          </div>}
        </div>
      </div>
    </>
  )
}

export default ThankyouScreen

