import React, { useContext, useState, useEffect } from "react";
import { Context } from "./Context";
import "../App.css";
import { docusignEnvironmentURL } from "./ApiUrls";
import axios from "axios";
import { submitAPIURL } from "../components/ApiUrls";
import ReactGA from "react-ga4";
import { thankYouScreenLabels } from "../components/ScreenLabels";
const EmployerAgreementDocusign = () => {
  const [context, setContext] = useContext(Context);
  const [spinner, setSpinner] = useState(true);
  const [spinnerZindex, setSpinnerZindex] = useState(false);
  const [confirmSubmissionBool, setConfirmSubmissionBool] = useState(false);
  const [agreedBool, setAgreedBool] = useState(false);
  const [loaderBool, setLoaderBool] = useState(false);
  let environment = docusignEnvironmentURL;
  let accountId = "";
  let clickwrapId = "";
  let clientUserId =
    context.postStep2Data.confirmemail +
    "_" +
    context.hireAgreementDetails.agreementTime;
  let name =
    context.postStep2Data.firstname + " " + context.postStep2Data.surname;

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();
  today = mm + "/" + dd + "/" + yyyy;
  
  useEffect(()=>{
    if (agreedBool){
      onClickSubmit();   
    }
    
  },[agreedBool]);
  useEffect(() => {
    const docusignIframe = document.querySelector('[data-qa="ClickwrapIframeApp"]');
    const handleIframeLoad = () => {
      setLoaderBool(true);
    }   
    docusignIframe.addEventListener('load', handleIframeLoad);
    return () => {
      // Clean up the event listener on component unmount
      docusignIframe.removeEventListener('load', handleIframeLoad);
    };
  }, []);
  useEffect(()=>{
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  },[]);

  const navigateToNextScreen = (responseMessage) => {
     if (
      responseMessage.toLowerCase() ===
      thankYouScreenLabels.success.toLowerCase()
    ) {
      ReactGA.event({category:'Cycle2Work',action:'cycle2work_confirm_submission'});
      sendEmailFromDocusign();
      setContext({
        ...context,
        status: 1,
        step: "thankyou",
        isPHAagreed: true,
      });
    } else if (
      responseMessage.toLowerCase() ===
      thankYouScreenLabels.already.toLowerCase()
    ) {
      ReactGA.event({category:'Cycle2Work',action:'cycle2work_existing_employer'});
      setContext({
        ...context,
        status: 2,
        step: "thankyou",
        isPHAagreed: true,
      });
    } else {
      setContext({
        ...context,
        status: 3,
        step: "thankyou",
        isPHAagreed: true,
      });
    }
  };
  const getToken = async ()=>{ 
    let tokenObj={}      
    await axios.get('/post')
    .then(res => {tokenObj=res.data})
    .catch(e=>{console.log(e)});   
    return tokenObj;     
  }

  const onClickSubmit = (e) => {
    setAgreedBool(false)
    setLoaderBool(false)
    const submitPostData = {
      Address1: context.postStep1Data.addressLine1,
      Address2: context.postStep1Data.addressLine2,
      Address3: context.postStep1Data.addressLine3,
      City: context.postStep1Data.addressTown,
      PostalCode: context.postStep1Data.addressPostCode,
      country: context.postStep1Data.addressCountry,
      countrycode: context.postStep1Data.addressCountryCode,
      CompanyID: context.postStep1Data.companyid,
      Companyphone: context.postStep1Data.companyphone,
      Email: context.postStep2Data.confirmemail,
      FirstName: context.postStep2Data.firstname,
      HearAboutus: context.postStep1Data.hearaboutus,
      InVoiceEmail: context.postStep2Data.confirminvoiceemail,
      JobTitle: context.postStep2Data.jobtitle,
      SurName: context.postStep2Data.surname,
      Telephone: context.postStep2Data.telephone,
      companyName: context.postStep1Data.companyname,
      comptype: context.postStep1Data.comptype,
      numofemp: context.postStep1Data.numofemp,
      schemelimit: context.postStep1Data.schemelimit,
      AgreementLink: "",
    };

    setSpinnerZindex(true);
    submitPostApi(submitPostData);
  };
  let status = 0;
  const submitPostApi = async(postData) => {
    let tokenObj= await getToken();
        let headers= {
            "Authorization": "Bearer " + tokenObj.token,
            "Ocp-Apim-Subscription-Key": tokenObj.subscriptionKey            
        }
    axios({
      url: submitAPIURL,
      method: "POST",
      data: postData,
      headers:headers
    })
      .then((resp) => { 
        setTimeout(() => {
          navigateToNextScreen(resp.data.Message);
        }, 400);     
      })
      .catch((err) => {
        setContext({
          ...context,
          status: 3,
          step: "thankyou",
          isPHAagreed: true,
        });
      });
  };
 
  const agreeButtonStyles = {
    fontSize: "18px",
    color: "white",
    fontWeight: "bold",
    fontFamily: "Helvetica, Arial, sans-serif",
    height: "40px",
    backgroundColor: "#28a745", // 'rgb(66, 186, 150)',
    borderRadius: "7px",
    border: "1px solid transparent",
    padding: ".375rem .75rem",
    ":hover": {
      backgroundColor: "#3860B2",
    },
  };
  let address = "";
  if (context.postStep1Data.address.length) {
    address = context.postStep1Data.address;
  } else {
    address = context.postStep1Data.addressLine1;
    address += context.postStep1Data.addressLine2.length
      ? ", " + context.postStep1Data.addressLine2
      : "";
    address += context.postStep1Data.addressLine3.length
      ? ", " + context.postStep1Data.addressLine3
      : "";
    address += ", " + context.postStep1Data.addressTown;
    address += ", " + context.postStep1Data.addressPostCode;
    address += ", " + context.postStep1Data.addressCountry;
  }

  let currentDate = new Date(Date.now())
    .toLocaleString("en-GB")
    .split(",")[0]
    .trim();
  const documentData = {
    fullName:
      context.postStep2Data.firstname.trim() +
      " " +
      context.postStep2Data.surname.trim(),
    email: context.postStep2Data.email,
    company: context.postStep1Data.companyname,
    title: context.postStep2Data.jobtitle,
    date: currentDate,
    agreementDate: currentDate,
    employerName: context.postStep1Data.companyname,
    employerRegisteredOffice: address,
    employerCompanyNumber: context.postStep1Data.companyid,
    commencementDate: currentDate,
  };

  accountId = context.hireAgreementDetails.employerAgreementAccountid;
  clickwrapId = context.hireAgreementDetails.employerAgreementClickwrapid;
  const sendEmailFromDocusign = () => {
    axios
      .post("/post", documentData)
      .then((res) => {
      })
      .catch((err) => {
        console.log(err);
      });
  };
  React.useLayoutEffect(() => {
    window.docuSignClick.Clickwrap.render(
      {
        environment: environment,
        accountId: accountId,
        clickwrapId: clickwrapId,
        clientUserId: clientUserId,

        onMustAgree: function (agreementData) {
          // Set a local variable if needing to distinguish new agreements
         
        },
        onAgreed(agreement) {
          // Called when either 1) the clientUserId has previously agreed 2) the clientUserId has clicked agree and completed successfully
          // setSpinnerZindex(true);
          setAgreedBool(true);
        },
        onAgreeing(agreementData) {
          setSpinnerZindex(true);
        },
        onDeclined(agreement) {
          // Called when the clientUserId has declined successfully
          setContext({
            ...context,
            step: "step2",
            isPHAagreed: false,
          });
        },
        onError(error) {
          console.log(error.errorMessage);
          setAgreedBool(false);
        },
        documentData: documentData,
        style: {
          header: {
            display: "none",
          },
          agreeButton: agreeButtonStyles,
        },
      },
      "#ds-clickwrap"
    );
  }, []);
  return (
    <>
    {!loaderBool && <>
      <div className={spinnerZindex ? "spinner-zindex" : "spinner"}></div>
    </>}
    {agreedBool && <div className= "spinner-zindex"></div>}
      <div id="ds-clickwrap"></div>
    </>
  );
};

export default EmployerAgreementDocusign;
