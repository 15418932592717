import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../components/Context';
import { commonLabels, entryScreenLabels } from "../components/ScreenLabels";
import BlurBackground from './BlurBackground';
import cycleToWorkLogo from '../assets/images/cycleToWorkLogo.jpg';
import axios from 'axios';
const EntryScreen = () => {
    const [context, setContext] = useContext(Context);
    const onClickRegister = () => {
        setContext({
          step: "step1",          
        });
    }
  
    useEffect(()=>{
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      clearCacheData();
    },[])
    // Function to clear complete cache data
    const clearCacheData = () => {
      caches.keys().then((names) => {
          names.forEach((name) => {
              caches.delete(name);
          });
      });
      // console.log('Complete Cache Cleared')
  };
    
    return (
      <>
        <div className="tabscreen-blur">
          <BlurBackground />
        </div>

        <div className="entrypage-content entrypage-top">
          <div>
            <img
              src={cycleToWorkLogo}
              alt="cycleToWorkLogo"
              className="blur-bg-logo"
            />
          </div>
          <div>
            <button
              className="register-button"
              type="submit"
              onClick={() => onClickRegister()}
            >
              {commonLabels.registerButton}
            </button>
            <div className="card card-overlay">
              <div className="card-overlay-header">
                {entryScreenLabels.introTextLine1}
              </div>
              <div className="card-overlay-text">
                {entryScreenLabels.introTextLine2}
              </div>
              <div className="card-overlay-header1">
                {entryScreenLabels.introTextLine3}
              </div>
              <div>
                <ul>
                  {entryScreenLabels.introTextLine4.map((listData, i) => (
                    <li key={i}>{listData.data}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default EntryScreen