import React, { useContext, useState, useEffect } from 'react';
import cycleToWorkLogo from '../assets/images/cycleToWorkLogo.jpg';
import { Context } from '../components/Context';
import { stepThreeLabels, commonLabels, thankYouScreenLabels } from '../components/ScreenLabels';
import { tabLabels } from "../components/ScreenLabels";
import axios from 'axios';
import { reqAssistanceAPIURL, homePageLink } from '../components/ApiUrls';
import { signinRedirectLink } from '../components/ApiUrls';
import EmployerAgreementDocusign from '../components/employerAgreementDocusign';
import {faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactGA from "react-ga4";
const StepThree = () => {
    const [currentTab, setCurrentTab] = useState(3);
    const [context, setContext] = useContext(Context);
    const [spinner, setSpinner] = useState(false);
    const [popup1, setPopup1] = useState(true);
    const [popup2, setPopup2] = useState(false);
    const [confirmBool, setConfirmBool] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [alreadyReg, setAlreadyReg] = useState(false);
    const [reqAssDisableBool, setReqAssDisableBool] = useState(false);
    const [confirmDisableBool, setConfirmDisableBool] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
    }, []);   
    const getToken = async ()=>{ 
        let tokenObj={}      
        await axios.get('/post')
        .then(res => {tokenObj=res.data})
        .catch(e=>{console.log(e)});   
        return tokenObj;     
      }
    const onClickReqAssistance = async() => {
        setReqAssDisableBool(true);
        setConfirmDisableBool(true);
        setSpinner(true);
        const reqAssistancePostData = {
            Address1: context.postStep1Data.addressLine1,
            Address2: context.postStep1Data.addressLine2,
            Address3: context.postStep1Data.addressLine3,
            City: context.postStep1Data.addressTown,
            PostalCode: context.postStep1Data.addressPostCode,
            country: context.postStep1Data.addressCountry,
            countrycode: context.postStep1Data.addressCountryCode,
            CompanyID: context.postStep1Data.companyid,
            Companyphone: context.postStep1Data.companyphone,
            Email: context.postStep2Data.confirmemail,
            FirstName: context.postStep2Data.firstname,
            HearAboutus: context.postStep1Data.hearaboutus,
            InVoiceEmail: context.postStep2Data.confirminvoiceemail,
            JobTitle: context.postStep2Data.jobtitle,
            SurName: context.postStep2Data.surname,
            Telephone: context.postStep2Data.telephone,
            companyName: context.postStep1Data.companyname,
            comptype: context.postStep1Data.comptype,
            numofemp: context.postStep1Data.numofemp,
            schemelimit: context.postStep1Data.schemelimit,
        };
        
        let tokenObj= await getToken();
        let headers= {
            "Authorization": "Bearer " + tokenObj.token,
            "Ocp-Apim-Subscription-Key": tokenObj.subscriptionKey             
        }
        axios({
            url: reqAssistanceAPIURL,
            method: "POST",
            data: reqAssistancePostData,
            headers:headers
        })
            .then((resp) => {
                setPopup1(false);
                setPopup2(true);
                setSpinner(false);
                if (resp.data.Message === "CompanyDetailsCaptured") {
                    ReactGA.event({category:'Cycle2Work',action:'cycle2work_request_assistance'});
                    setSuccessMsg(true);
                } else if (resp.data.Message === "Company Already Registered") {
                    ReactGA.event({category:'Cycle2Work',action:'cycle2work_existing_employer'});
                    setAlreadyReg(true);
                }
            })
            .catch((err) => {
                setPopup1(false);
                setPopup2(true);
                setErrorMsg(true);
                setSpinner(false);
            });

    }

    const onClickConfirm = () => {
        ReactGA.event({category:'Cycle2Work',action:'cycle2work_docusign'});
        setPopup1(false);
        setConfirmBool(true);
    }

    const closePopup2 = () => {
        setPopup2(false);
        if (document.referrer) {
            window.top.location.href = document.referrer;
        } else {
            window.location.href = homePageLink;
        }
    }

    const redirect = (e) => {
        e.preventDefault();
        // window.top.location.href=thankYouScreenLabels.link;
        // document.referrer?window.top.location.href=thankYouScreenLabels.link:window.location.href=thankYouScreenLabels.link;
        // window.location.href=signinRedirectLink;
        if (document.referrer) {
            window.top.location.href = signinRedirectLink;
        } else {
            window.location.href = signinRedirectLink;
        }
    }


    return (
        <>
            {spinner && <div className={popup1 || popup2 ? "spinner-zindex" : "spinner"}></div>}
            <div className={spinner || popup1 || popup2 ? "tabscreen-blur" : "content"}>
                <div className='tab-container'>
                    <div className='tabs'>
                        {tabLabels.map((tab, i) =>
                            <button style={{ cursor: "auto" }} key={i} id={tab.id} disabled={currentTab == `${tab.id}`}>{tab.title}</button>
                        )}
                    </div>
                    <div className='step-main-container'>
                        <div className="row">
                            <div className="col-sm form-left">
                                <div className="col-sm step3-tab-header">{stepThreeLabels.header1}<br />{stepThreeLabels.header2}</div>
                            </div>
                            <div className="col-sm mt-3">
                                <img src={cycleToWorkLogo} alt="cycleToWorkLogo" className='cycleToWorkLogo' />
                            </div>
                        </div>
                        <div className="mb-5">
                            {confirmBool && <div className="mb-5">
                                <EmployerAgreementDocusign />
                            </div>}
                        </div>

                    </div>
                </div>
            </div>
            {popup1 && <div className='card card-popup-overlay'>
               <div className="card-overlay-popup-text">
                    {stepThreeLabels.content1}
                </div>
                <div className="card-overlay-popup-text">
                    {stepThreeLabels.content2}
                </div>
                <div>
                    <div className='mt-5'>
                        <button className='req-assistance-button' onClick={() => onClickReqAssistance()} disabled={reqAssDisableBool}>{stepThreeLabels.reqAssistanceButton}</button>
                        <button className='confirm-button' onClick={() => onClickConfirm()} disabled={confirmDisableBool}>{stepThreeLabels.confirmButton}</button>
                    </div>
                </div>
            </div>}
            {popup2 && <div className='card card-popup-overlay'>
                {successMsg && <div>
                    <div className='row d-flex'>
                        <div className="col-sm-11 card-overlay-popup-text pl-5">
                            {stepThreeLabels.content3}
                        </div>
                        <div>
                            <button className='close-button' onClick={() => { closePopup2() }}>
                                {/* <i className='fa fa-close'></i> */}
                                <FontAwesomeIcon icon={faClose} />
                            </button>
                        </div>
                    </div>

                    <div className="card-overlay-popup-text">
                        {stepThreeLabels.content4}
                    </div>
                </div>}
                {errorMsg && <div>
                    <div className='row d-flex'>
                        <div className="col-sm-11 card-overlay-popup-text pl-5">
                            {stepThreeLabels.content5}
                        </div>
                        <div>
                            <button className='close-button' onClick={() => { closePopup2() }}>
                                {/* <i className='fa fa-close'></i> */}
                                <FontAwesomeIcon icon={faClose} />
                            </button>
                        </div>
                    </div>

                    <div className="card-overlay-popup-text">
                        {stepThreeLabels.content6}
                    </div>
                </div>}
                {alreadyReg && <div>
                    <div className='row d-flex'>
                        <div className="col-sm-11 card-overlay-popup-text pl-5">
                            {thankYouScreenLabels.already}
                        </div>
                        <div>
                            <button className='close-button' onClick={() => { closePopup2() }}>
                                {/* <i className='fa fa-close'></i> */}
                                <FontAwesomeIcon icon={faClose} />
                            </button>
                        </div>
                    </div>

                    <div className="card-overlay-popup-text">
                        <div className="card-overlay-text">
                            <a href='#' className='signin-link' onClick={(e) => { redirect(e) }}>{thankYouScreenLabels.linktext}</a>
                        </div>
                    </div>
                </div>}
                <div className="card-overlay-popup-text">
                    {thankYouScreenLabels.content4}<br />
                    {thankYouScreenLabels.content5}
                </div>
            </div>}
        </>
    )
}

export default StepThree