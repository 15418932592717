import React, { useState, useContext } from 'react';
import { commonLabels } from "../components/ScreenLabels";
import { Context } from '../components/Context';
import {faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const NetworkError = (props) => {
  const [context, setContext] = useContext(Context);
  const [errorPopup, setErrorPopup] = useState(true);
  const onClickOk = () => {
    setErrorPopup(false);
    if(props.setNetworkPopup){
      props.setNetworkPopup(false);
    }
   
  }

  return (
    <>
      {errorPopup && <div className='card-network-popup-overlay'>
        <div className='text-center'>
          {/* <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> */}
          <FontAwesomeIcon icon={faExclamationTriangle} />
          <div className='card-overlay-popup-text'>
            {commonLabels.networkMsg}
          </div>
          <button className='ok-button' onClick={() => onClickOk()}>{commonLabels.okButtonText}</button>
        </div>
      </div>}
    </>
  )
}

export default NetworkError