import React, { useState, useContext, useEffect } from 'react';
import cycleToWorkLogo from '../assets/images/cycleToWorkLogo.jpg';
import loading from '../assets/images/loading.png';
import icon1 from '../assets/images/icon1.svg';
import icon2 from '../assets/images/icon2.svg';
import { stepOneLabels, commonLabels, validationMsg } from "../components/ScreenLabels";
import { Context } from '../components/Context';
import { tabLabels } from "../components/ScreenLabels";
import axios from 'axios';
import { industryListAPIURL, compRegNumberValidationAPIURl, experianAddressSearchAPIURL, experianDetailsFromTableAPIURL, experianDetailsFromTablePostData, errorMessageAPIURL, hireAgreementAPIUrl,  } from '../components/ApiUrls';
import Autocomplete from "react-autocomplete";
import { faCircleXmark,faCircleExclamation,faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NetworkError from './NetworkError';
import ReactGA from "react-ga4";

const StepOne = () => {
  const [industryList, setIndustryList] = useState([]);
  const [currentTab, setCurrentTab] = useState(1);
  const [context, setContext] = useContext(Context);
  const [status, setStatus] = useState(context.postStep1Data ? context.postStep1Data.status : 0);
  const [hearaboutusList, setHearaboutusList] = useState([])
  const errorMessageAPIpostData = { errormessages: "string" };
  const [postdataForExperianAPI, setPostdataForExperianAPI] = useState({
    country_iso: "GBR",
    datasets: "gb-address",
    max_suggestions: 100,
    "Auth-Token": "7738cb7f-af41-447f-ac3b-692e2c16c6ea",
  });

  const [companyname, setCompanyname] = useState(context.postStep1Data ? context.postStep1Data.companyname : "");
  const [companyid, setCompanyid] = useState(context.postStep1Data ? context.postStep1Data.companyid : "");
  const [address, setAddress] = useState(context.postStep1Data ? context.postStep1Data.address : "");
  const [addressLine1, setAddressLine1] = useState(context.postStep1Data ? context.postStep1Data.addressLine1 : "");
  const [addressLine2, setAddressLine2] = useState(context.postStep1Data ? context.postStep1Data.addressLine2 : "");
  const [addressLine3, setAddressLine3] = useState(context.postStep1Data ? context.postStep1Data.addressLine3 : "");
  const [addressTown, setAddressTown] = useState(context.postStep1Data ? context.postStep1Data.addressTown : "");
  const [addressPostCode, setAddressPostCode] = useState(context.postStep1Data ? context.postStep1Data.addressPostCode : "");
  const [addressCountry, setAddressCountry] = useState(context.postStep1Data ? context.postStep1Data.addressCountry : "");
  const [addressCountryCode, setAddressCountryCode] = useState(context.postStep1Data ? context.postStep1Data.addressCountryCode : 4);
  const [numofemp, setNumofemp] = useState(context.postStep1Data ? context.postStep1Data.numofemp : "");
  const [comptype, setComptype] = useState(context.postStep1Data ? context.postStep1Data.comptype : "");
  const [companyphone, setCompanyphone] = useState(context.postStep1Data ? context.postStep1Data.companyphone : "");
  const [schemelimit, setSchemelimit] = useState(context.postStep1Data ? context.postStep1Data.schemelimit : "");
  const [hearaboutus, setHearaboutus] = useState(context.postStep1Data ? context.postStep1Data.hearaboutus : "");

  const [addrBlurBool, setAddrBlurBool] = useState(false);
  const [addrManualBool, setAddrManualBool] = useState(context.postStep1Data ? context.postStep1Data.addrManualBool:false);
  const [addrInvalidBool, setAddrInvalidBool] = useState(false);
  const [suggArray, setSuggArray] = useState([]);
  const [selectedAddressBool, setSelectedAddressBool] = useState(false);
  const [autofillBool, setAutofillBool] = useState(context.postStep1Data ? context.postStep1Data.autofillBool : true);
  const [loader, setLoader] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  const [invalidCompNameBool, setInvalidCompNameBool] = useState(false);
  const [compRegBoolValMsg, setCompRegBoolValMsg] = useState(false);
  const [companyidEmptyBool, setCompanyidEmptyBool] = useState(false);

  const [validRegBool, setValidRegBool] = useState(false);
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false);
  const [invalidSchemelimit, setInvalidSchemelimit] = useState(false);
  const [companynameEmptyBool, setCompanynameEmptyBool] = useState(false);
  const [statusEmptyBool, setStatusEmptyBool] = useState(false);

  const [addressEmptyBool, setAddressEmptyBool] = useState(false);
  const [addressLine1EmptyBool, setAddressLine1EmptyBool] = useState(false);
  const [addressTownEmptyBool, setAddressTownEmptyBool] = useState(false);
  const [addressPostCodeEmptyBool, setAddressPostCodeEmptyBool] = useState(false);
  const [addressCountryEmptyBool, setAddressCountryEmptyBool] = useState(false);
  const [numofempEmptyBool, setNumofempEmptyBool] = useState(false);
  const [comptypeEmptyBool, setComptypeEmptyBool] = useState(false);
  const [companyphoneEmptyBool, setCompanyphoneEmptyBool] = useState(false);
  const [schemelimitEmptyBool, setSchemelimitEmptyBool] = useState(false);
  const [networkPopup, setNetworkPopup] = useState(false);

  let bool = true;
  let addFilledBool = false;
  if (addrManualBool) {
    (addressLine1 !== "" && addressTown !== "" && addressPostCode !== "" && addressCountry !== "") ? addFilledBool = true : addFilledBool = false;
  } else {
    address !== "" ? addFilledBool = true : addFilledBool = false;
  }
  let companyidBool = false
  if (status === 1 && companyid !== "") {
    if (compRegBoolValMsg) {
      companyidBool = false
    } else if (validRegBool) {
      companyidBool = true
    }
    else if (context.postStep1Data) {
      companyidBool = true
    }
  } else if (status === 2) {
    companyidBool = true
  }

  if (
    companyname !== "" && companyidBool && addFilledBool && numofemp !== "" && 
    comptype !== "" && companyphone !== "" && schemelimit !== "" && hearaboutus !== ""
    && !invalidCompNameBool && !compRegBoolValMsg &&
    !invalidPhoneNumber && !invalidSchemelimit && !addrInvalidBool
  ) {
    bool = false;
  }


  const getHireAgreementDetails = (headers) => {
    
    axios({
      url: hireAgreementAPIUrl,
      method: "POST",
      headers:headers
    })
      .then((resp) => {
        let response = resp.data;
        let json = {};
        json["employerAgreementAccountid"] =
          response.ssp_employerssagreementtemplateaccountid;
        json["employerAgreementClickwrapid"] =
          response.ssp_employerssagreementtemplateclickwrapid;

        json["agreementTime"] = new Date().getTime();
        setContext({
          ...context,
          hireAgreementDetails: json,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const radioHandler = (status) => {
    setStatus(status);
    setCompRegBoolValMsg(false);
    setStatusEmptyBool(false)
    if (status === 2) {
      setCompanyid("");
      setCompRegBoolValMsg(false);
      setValidRegBool(false);
    }
  };      

  const onClickStep1Next = () => {
    
    const postStep1Data = {
      companyname: companyname,
      companyid: companyid,
      address: address.replace(/[,]/g, ""),
      addressLine1: addressLine1.replace(/[,]/g, ""),
      addressLine2: addressLine2.replace(/[,]/g, ""),
      addressLine3: addressLine3.replace(/[,]/g, ""),
      addressTown: addressTown.replace(/[,]/g, ""),
      addressPostCode: addressPostCode.replace(/[,]/g, ""),
      addressCountry: addressCountry,
      addressCountryCode:addressCountryCode,
      status: status,
      numofemp: numofemp,
      comptype: comptype,
      companyphone: companyphone,
      schemelimit: schemelimit,
      hearaboutus: hearaboutus,
      autofillBool: autofillBool,
      addrManualBool:addrManualBool
    }
    ReactGA.event({category:'Cycle2Work',action:'cycle2work_company_details'});
    console.log('evnt fired');
    setContext({ ...context, step: "step2", postStep1Data, errorMessages: errorMessages, });
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    onLoadData();
  }, []);
  useEffect(() => {
    if (context.step === "step1") {
      if (address && address.length >= 4) {
        const postData = getPostDataForExperian(address);
        if (!addrBlurBool) {
          getAutoCompleteList(postData);
        }
      } else {
        setSuggArray([]);
      }
    }
  }, [address, addrBlurBool]);

  const onLoadData = async()=>{
    if (context.step === "step1") {

      let tokenObj= await getToken();
      let headers= {
        "Authorization": "Bearer " + tokenObj.token,
        "Ocp-Apim-Subscription-Key": tokenObj.subscriptionKey            
      }

      getExperianDetailsFromTable(headers);
      getErrorMessagesAPI(headers);
      getHireAgreementDetails(headers);
      if (!industryList.length)
        getIndustryListFromAPI(headers);
      }
  }
  const getToken = async ()=>{ 
      let tokenObj={}      
      await axios.get('/post')
      .then(res => {tokenObj=res.data})
      .catch(e=>{console.log(e)});   
      return tokenObj;     
    }
  
  const getIndustryListFromAPI = (headers) => {
    axios({
      url: industryListAPIURL,
      method: "POST",
      headers:headers
    })
      .then((resp) => {
        let industryResponse = resp.data.IndustryTypes;
        let result = []
        if (industryResponse.indexOf(';') > -1) {
          result = industryResponse.split(';')
        } else {
          result.push(industryResponse);
        }

        setIndustryList(result);
        let hearaboutusResponse = resp.data.HearAboutUs;
        let hearaboutresult = []
        if (hearaboutusResponse.indexOf(';') > -1) {
          hearaboutresult = hearaboutusResponse.split(';')
        } else {
          hearaboutresult.push(hearaboutusResponse)
        }
        setHearaboutusList(hearaboutresult);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setCompRegValidationOnBlur = async(data) => {

    if (data.length) {
      if (data.length > 7) {
        let postData = {
          id: data
        }
        setLoader(true);

        let tokenObj= await getToken();
        let headers= {
          "Authorization": "Bearer " + tokenObj.token,
          "Ocp-Apim-Subscription-Key": tokenObj.subscriptionKey             
        }
        axios({
          url: compRegNumberValidationAPIURl,
          method: "POST",
          headers:headers,
          data: postData
        })
          .then((resp) => {
            setLoader(false);
            let response = resp.data;
            if (response.details == validationMsg.valiCompId) {
              setCompRegBoolValMsg(false);
              setValidRegBool(true);
            } else {
              setCompRegBoolValMsg(true);
              setValidRegBool(false);
            }
          })
          .catch((err) => {
            setCompRegBoolValMsg(true);
            setValidRegBool(false);
            setLoader(false);
          });
      } else {
        setCompRegBoolValMsg(true);
        setValidRegBool(false);
        setLoader(false);
      }
    }

  }

  const compRegOnFocus = (e) => {
    if (!(invalidPhoneNumber || invalidSchemelimit || invalidCompNameBool)) {
      setCompRegBoolValMsg(false);
      setValidRegBool(false);
      setCompanyidEmptyBool(false);

    }
  }

  const getExperianDetailsFromTable = (headers) => {
    axios({
      url: experianDetailsFromTableAPIURL,
      method: "POST",
      data: experianDetailsFromTablePostData,
      headers:headers
    })
      .then((res) => {
        if (res.data) setPostdataForExperianAPI(res.data);
      })
      .catch((err) => {
        console.log("err");
      });
  };


  const getPostDataForExperian = (address) => {
    const postData = {
      country_iso: postdataForExperianAPI.country_iso,
      datasets: [postdataForExperianAPI.datasets],
      max_suggestions: postdataForExperianAPI.max_suggestions,
      components: {
        unspecified: [address],
      },
    };
    return postData;
  };

  const getAutoCompleteList = (postData) => {
    let cancelToken;
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
      setSuggArray([]);
      console.log("request cancelled");
    }
    cancelToken = axios.CancelToken.source();
    axios({
      url: experianAddressSearchAPIURL,
      method: "POST",
      headers: { "Auth-Token": postdataForExperianAPI["Auth-Token"] },      
      data: postData,
      cancelToken: cancelToken.token,
    })
      .then((res) => {
        if (res.data.result.suggestions) {
          var resultArray = res.data.result.suggestions.map((item, index) => {
            return { label: item.text, id: parseInt(index + 1), format: item.format };
          });
          setSuggArray([
            { label: stepOneLabels.chooseManually, id: 0 },
            ...resultArray,
          ]);
        } else {
          setSuggArray([
            { label: stepOneLabels.chooseManually, id: 0 },
          ]);
        }
      })
      .catch((err) => {
        console.log("err");
         err.code === "ERR_NETWORK"?setNetworkPopup(true):setNetworkPopup(false);
      });
  };

  const addressAutocompleteSelect = (val) => {
    if (val === stepOneLabels.chooseManually) {

      setAddress('');
      setAutofillBool(false);
      setAddrManualBool(true);
      setAddressLine1('');
      setAddressLine2('');
      setAddressLine3('');
      setAddressTown('');
      setAddressPostCode('');
      setAddressCountry('');
    } else {
      setAddress(val);
      setAddrBlurBool(true);
      suggArray.map((item) => {
        if (item.label === val) {
          getSplitAddressDetails(item.format, val);
        }
      })
    }
    setSuggArray([]);
  };

  const getSplitAddressDetails = (addressFormatURL, val) => {
    axios({
      url: addressFormatURL,
      method: "GET",
      headers: { "Auth-Token": postdataForExperianAPI["Auth-Token"]},
    })
      .then((res) => {
        if (res.data) {
          let splitAddr = res.data.result.address;
          setAddressLine1(splitAddr.address_line_1);
          setAddressLine2(splitAddr.address_line_2);
          setAddressLine3(splitAddr.address_line_3);
          setAddressTown(splitAddr.locality);
          setAddressPostCode(splitAddr.postal_code);
          setAddressCountry(splitAddr.country);
          splitAddr.country.toLowerCase().trim() ==="united kingdom"?setAddressCountryCode(4):setAddressCountryCode(7);
        } else {
          splitAddressManually(val);
        }
      })
      .catch((err) => {
        console.log("err", err);
        splitAddressManually(val);
      });
  }

  const splitAddressManually = (val) => {
    if (val.indexOf(',') > -1) {
      let splitAddr = val.split(',')
      setAddressPostCode(splitAddr.pop().trim());
      setAddressCountry(splitAddr.pop().trim());
      setAddressTown(splitAddr.pop().trim());
      if (splitAddr.length > 3) {
        setAddressLine3(splitAddr.pop().trim());
        setAddressLine2(splitAddr.pop().trim());
        let str = '';
        splitAddr.map((i) => {
          str += i;
        })
        setAddressLine1(str.trim());
      } else if (splitAddr.length < 3) {
        if (splitAddr.length == 2) {
          setAddressLine1(splitAddr[0].trim());
          setAddressLine2(splitAddr[1].trim());
          setAddressLine3('');
        } else if (splitAddr.length == 1) {
          setAddressLine1(splitAddr[0]);
          setAddressLine2('');
          setAddressLine3('');
        } else {
          setAddressLine1('');
          setAddressLine2('');
          setAddressLine3('');
        }
      } else {
        setAddressLine1(splitAddr[0].trim());
        setAddressLine2(splitAddr[1].trim());
        setAddressLine3(splitAddr[2].trim());
      }
    }
  }

  const getErrorMessagesAPI = (headers) => {
    axios({
      url: errorMessageAPIURL,
      method: "POST",
      data: errorMessageAPIpostData,
      headers:headers
    })
      .then((resp) => {
        let response = resp.data;
        response =
          response.slice(0, response.lastIndexOf(",")) +
          response.slice(response.lastIndexOf(",") + 1);
        response = JSON.parse(response);
        setErrorMessages(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const compNameOnBlur = (e) => {
    if (!companyname.length) {
      hearaboutus.length ? setCompanynameEmptyBool(true) : setCompanynameEmptyBool(false)
    }
  };

  const compNameOnFocus = (e) => {
    if (!(invalidPhoneNumber || invalidSchemelimit)) {
      setInvalidCompNameBool(false);
      setCompanynameEmptyBool(false);
    }
  };

  const companyPhoneOnBlur = (e) => {
    if (!companyphone.length) {
      hearaboutus.length ? setCompanyphoneEmptyBool(true) : setCompanyphoneEmptyBool(false)
    // } else if (!/^[0-9]*$/.test(companyphone) || companyphone.length < 11) {
    }else if(companyphone.length<commonLabels.mobileNumberMinLen || companyphone.length >commonLabels.mobileNumberMaxLen){
      setInvalidPhoneNumber(true);
    } else {
      setInvalidPhoneNumber(false);
      setCompanyphoneEmptyBool(false)
    }
  }

  const companyPhoneOnFocus = (e) => {
    if (!(invalidCompNameBool || invalidSchemelimit)) {
      setInvalidPhoneNumber(false);
      setCompanyphoneEmptyBool(false);
    }

  }

  const schemelimitOnBlur = (e) => {
    if (!schemelimit.length) {
      hearaboutus.length ? setSchemelimitEmptyBool(true) : setSchemelimitEmptyBool(false)
    } else {
      if (schemelimit < 100) {
        setInvalidSchemelimit(true)
      }
    }
  }

  const schemelimitOnFocus = (e) => {
    setInvalidSchemelimit(false);
    setSchemelimitEmptyBool(false);
  }

  const companyNameOnChange = (e) => {
    setCompanyname(e.target.value);
  }

  const companyIdOnChange = (e) => {
    setCompanyid(e.target.value);
  }

  const uknumOnChange = (e) => {
    let val = e.target.value.replace(/[^0-9]/g, "");
    setNumofemp(val);
  }
  const numofempOnBlur = () => {
    if (!numofemp.length) {
      hearaboutus.length ? setNumofempEmptyBool(true) : setNumofempEmptyBool(false)
    }
  }

  const comptypeOnBlur = () => {
    if (!comptype.length) {
      hearaboutus.length ? setComptypeEmptyBool(true) : setComptypeEmptyBool(false)
    }
  }
  const phonenumberOnChange = (e) => {
    let val = e.target.value.replace(/[^0-9]/g, "");
    // setMobile(e.target.value.replace(/[^0-9]/g, ""))
    setCompanyphone(val);
  }

  const schemelimitOnChange = (e) => {
    let val = e.target.value.replace(/[^0-9\s]/g, "");
    setSchemelimit(val);
  }
  const faCircleXmarkOnClick = () => {
    setAutofillBool(true);
    setAddrManualBool(false);
    setAddress("");
    setSuggArray([]);
  }

  const addressLine1OnBlur = (e) => {

    if (!addressLine1.length) {
      hearaboutus.length ? setAddressLine1EmptyBool(true) : setAddressLine1EmptyBool(false)
    }
  }
  const addressLine1OnFocus = (e) => {
    if (!(invalidPhoneNumber || invalidSchemelimit || compRegBoolValMsg || invalidCompNameBool)) {

      setAddressLine1EmptyBool(false);
    }
  }

  const addressTownOnFocus = (e) => {
    if (!(invalidPhoneNumber || invalidSchemelimit || compRegBoolValMsg || invalidCompNameBool)) {

      setAddressTownEmptyBool(false);
    }
  }

  const addressTownOnBlur = (e) => {
    if (!addressTown.length) {
      hearaboutus.length
        ? setAddressTownEmptyBool(true)
        : setAddressTownEmptyBool(false);
    } else {
      setAddressTownEmptyBool(false);
    }
  };

  const addressPostCodeOnBlur = (e) => {
    if (!addressPostCode.length) {
      hearaboutus.length ? setAddressPostCodeEmptyBool(true) : setAddressPostCodeEmptyBool(false)
    } else {
      setAddressPostCodeEmptyBool(false)
    }
  }

  const addressPostCodeOnFocus = (e) => {
    if (!(invalidPhoneNumber || invalidSchemelimit || compRegBoolValMsg || invalidCompNameBool)) {
      setAddressPostCodeEmptyBool(false);
    }
  }
  const addressCountryOnChange = (e) => {
let val=e.target.value;
setAddressCountry(val);
val.toLowerCase().trim() ==="united kingdom"?setAddressCountryCode(4):setAddressCountryCode(7);


  }
  const addressCountryOnBlur = (e) => {
    if (!addressCountry.length) {
      hearaboutus.length ? setAddressCountryEmptyBool(true) : setAddressCountryEmptyBool(false)
    } else {
      setAddressCountryEmptyBool(false)
    }
  }

  const addressCountryOnFocus = (e) => {
    if (!(invalidPhoneNumber || invalidSchemelimit || compRegBoolValMsg || invalidCompNameBool)) {
      setAddressCountryEmptyBool(false);
    }
  }

  const autocompleteInputOnBlur = () => {
    if (address !== '') {
      !selectedAddressBool ? setAddrInvalidBool(true) : setAddrInvalidBool(false);
    } else {
      hearaboutus.length ? setAddressEmptyBool(true) : setAddressEmptyBool(false)
    }
    setAddrBlurBool(true);
  }

  const autocompleteInputOnFocus = () => {
    if (!(invalidPhoneNumber || invalidSchemelimit || compRegBoolValMsg || invalidCompNameBool)) {
      setAddrBlurBool(false);
      setAddressEmptyBool(false);
      setSelectedAddressBool(false);
      setAddrInvalidBool(false);
    }
  }

  const autocompleteInputStyles = {
    borderRadius: "5px",
    height: "40px",
    width: "100%",
  }

  const autocompleteInputStylesEmpty = {
    border: "0.5px solid #DC3545",
    borderRadius: "5px",
    height: "40px",
    width: "100%",
  }

  const fieldEmptyValidation = () => {
    if (hearaboutus !== "") {
      companyname === "" ? setCompanynameEmptyBool(true) : setCompanynameEmptyBool(false);
      companyid === "" ? setCompanyidEmptyBool(true) : setCompanyidEmptyBool(false);
      status === 0 ? setStatusEmptyBool(true) : setStatusEmptyBool(false);
      address === "" ? setAddressEmptyBool(true) : setAddressEmptyBool(false);
      addressLine1 === ""
        ? setAddressLine1EmptyBool(true)
        : setAddressLine1EmptyBool(false);
      addressTown === ""
        ? setAddressTownEmptyBool(true)
        : setAddressTownEmptyBool(false);
      addressPostCode === ""
        ? setAddressPostCodeEmptyBool(true)
        : setAddressPostCodeEmptyBool(false);
      addressCountry === ""
        ? setAddressCountryEmptyBool(true)
        : setAddressCountryEmptyBool(false);
      numofemp === "" ? setNumofempEmptyBool(true) : setNumofempEmptyBool(false);
      comptype === "" ? setComptypeEmptyBool(true) : setComptypeEmptyBool(false);
      companyphone === "" ? setCompanyphoneEmptyBool(true) : setCompanyphoneEmptyBool(false);
      schemelimit === "" ? setSchemelimitEmptyBool(true) : setSchemelimitEmptyBool(false);
    } else {
      setCompanynameEmptyBool(false);
      setStatusEmptyBool(false)
      setCompanyidEmptyBool(false);
      setAddressEmptyBool(false);
      setAddressLine1EmptyBool(false);
      setAddressTownEmptyBool(false);
      setAddressPostCodeEmptyBool(false);
      setAddressCountryEmptyBool(false);
      setNumofempEmptyBool(false);
      setComptypeEmptyBool(false);
      setCompanyphoneEmptyBool(false);
      setSchemelimitEmptyBool(false);
    }
  };

  return (
    <>
      <div className='content'>
        <div className='tab-container'>
          <div className='tabs'>
            {tabLabels.map((tab, i) =>
              <button style={{ cursor: "auto" }} disabled={currentTab == `${tab.id}`} key={i} id={tab.id}>{tab.title}</button>
            )}
          </div>
          <div className="step-main-container">
            <div className="row bg-image">
              <div className="col-sm form-left">
                <div className="tab-header">{stepOneLabels.header}</div>
                <div className='form-section'>
                  <div className='form-label'>{stepOneLabels.compName}<span className='asterisk'>*</span></div>
                  <input type="text" className={companynameEmptyBool ? "form-control border-danger" : "form-control"} maxLength={stepOneLabels.compNameMaxLeg}
                    value={companyname}
                    onChange={(e) =>
                      companyNameOnChange(e)
                    }
                    onBlur={(e) => { compNameOnBlur(e) }} onFocus={(e) => { compNameOnFocus(e) }} readOnly={invalidPhoneNumber || invalidSchemelimit || compRegBoolValMsg || addrInvalidBool} autoComplete="no" />
                </div>
                {invalidCompNameBool && (
                  <label className="error-msg">
                    {errorMessages.name}
                  </label>
                )}
                <div className='form-section'>
                  <div className='form-label'>{stepOneLabels.regNumAvailability}<span className='asterisk'>*</span></div>
                  <div className={statusEmptyBool ? "form-control border-danger d-flex" : "d-flex"}>
                    <div className='pr-4'><input type="radio" id={stepOneLabels.radioButtonLabel1} name={stepOneLabels.radioButtonLabel1} value={stepOneLabels.radioButtonLabel1} checked={status === 1}
                       onChange={() => radioHandler(1)}
                      disabled={invalidPhoneNumber || invalidSchemelimit || invalidCompNameBool} />
                      <span className='radio-element pl-2' htmlFor={stepOneLabels.radioButtonLabel1}>{stepOneLabels.radioButtonLabel1}</span></div>
                    <div><input type="radio" id={stepOneLabels.radioButtonLabel2} name={stepOneLabels.radioButtonLabel1} value={stepOneLabels.radioButtonLabel2} checked={status === 2}
                      onChange={() => radioHandler(2)}
                      disabled={invalidPhoneNumber || invalidSchemelimit || invalidCompNameBool || addrInvalidBool} />
                      <span className='radio-element pl-2' htmlFor={stepOneLabels.radioButtonLabel2}>{stepOneLabels.radioButtonLabel2}</span></div>
                  </div>
                </div>
                {status === 1 && <div className='form-section'>
                  <div className='form-label'>{stepOneLabels.compRegNum}<span className='asterisk'>*</span></div>
                  <div className='d-flex'>
                    <input type="text" className={companyidEmptyBool ? "form-control border-danger" : "form-control"} autoComplete="no" maxLength={stepOneLabels.compRegNumMaxLen}
                      onChange={(e) => companyIdOnChange(e)} onBlur={(e) => setCompRegValidationOnBlur(e.target.value)} onFocus={(e) => {
                        compRegOnFocus(e);
                      }} value={companyid} readOnly={invalidPhoneNumber || invalidSchemelimit || invalidCompNameBool || addrInvalidBool} />
                    {loader && <div>
                      <img className="loader" src={loading} alt="loader" />
                    </div>}
                  </div>
                </div>}

                {compRegBoolValMsg && <label className='error-msg'>
                  {validationMsg.validRegNo}
                </label>}
                {validRegBool && <label className='valid-msg'>{validationMsg.valiCompId}</label>}
                {autofillBool && <div className='form-section'>
                  <div className='form-label'>{stepOneLabels.searchAdd}<span className='asterisk'>*</span></div>
                  <div className="autocomplete-element">
                    <Autocomplete
                      getItemValue={(item) => item.label}
                      items={suggArray}
                      renderItem={(item, isHighlighted) =>
                        <div style={{ background: isHighlighted ? 'lightgray' : 'white' }} key={item.id}>
                          {item.label}
                        </div>
                      }
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      onSelect={(val) => {
                        setSelectedAddressBool(true);
                        addressAutocompleteSelect(val);
                      }}
                      inputProps={{
                        style: !addressEmptyBool
                          ? autocompleteInputStyles
                          : autocompleteInputStylesEmpty,
                        onBlur: () => autocompleteInputOnBlur(),
                        onFocus: () => autocompleteInputOnFocus(),
                        readOnly: (invalidPhoneNumber || invalidSchemelimit || compRegBoolValMsg || invalidCompNameBool)
                      }}
                    />
                    <div className='form-label'>
                      <span className={(invalidPhoneNumber || invalidSchemelimit || compRegBoolValMsg || invalidCompNameBool) ? "address-manual-disable" : "address-manual-span"} onClick={() => {
                        setSelectedAddressBool(true);
                        addressAutocompleteSelect(stepOneLabels.chooseManually);
                        setAddrInvalidBool(false);
                      }}>{stepOneLabels.manuallEnter}</span>
                    </div>
                    {addrInvalidBool && (
                      <>
                        <div>
                          <label className="error-msg">
                            {errorMessages.address}
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                </div>}
                {!autofillBool && (
                  <div className="form-group">
                    <div className="form-group d-flex mt-2">
                      <div>
                        <em>{stepOneLabels.enterManually}</em>
                      </div>
                      <span
                        className="circle-xmark-element tooltip-icon"
                        onClick={() => {faCircleXmarkOnClick();}}>
                        <FontAwesomeIcon icon={faCircleXmark} />
                        <span className="tooltiptext">{stepOneLabels.cancelTooltip}
                        </span>
                      </span>
                    </div>
                    <div>
                      <label className="custom-text-justify">
                        {stepOneLabels.addLine1}
                      </label>
                      <span className='asterisk'>*</span>
                      <input
                        type="text"
                        autoComplete="no"
                        className={addressLine1EmptyBool ? "form-control border-danger" : "form-control"}
                        maxLength={stepOneLabels.addressLine1MaxLength}
                        onChange={(e) => setAddressLine1(e.target.value.replace(/[,.]/g, ""))}
                        onBlur={(e) => addressLine1OnBlur(e)}
                        onFocus={(e) => addressLine1OnFocus(e)}
                        value={addressLine1}
                        readOnly={invalidPhoneNumber || invalidSchemelimit || compRegBoolValMsg || invalidCompNameBool}
                        required />
                    </div>
                    <div>
                      <label className="custom-text-justify">
                        {stepOneLabels.addLine2}
                      </label>
                      <input
                        type="text"
                        autoComplete="no"
                        className="form-control"
                        maxLength={stepOneLabels.addressLine2MaxLength}
                        onChange={(e) => setAddressLine2(e.target.value.replace(/[,.]/g, ""))}
                        value={addressLine2}
                        readOnly={invalidPhoneNumber || invalidSchemelimit || compRegBoolValMsg || invalidCompNameBool}
                      />
                    </div>
                    <div>
                      <label className="custom-text-justify">
                        {stepOneLabels.addLine3}
                      </label>
                      <input
                        type="text"
                        autoComplete="no"
                        className="form-control"
                        maxLength={stepOneLabels.addressLine2MaxLength}
                        onChange={(e) => setAddressLine3(e.target.value.replace(/[,.]/g, ""))}
                        value={addressLine3}
                        readOnly={invalidPhoneNumber || invalidSchemelimit || compRegBoolValMsg || invalidCompNameBool}
                      />
                    </div>
                    <div>
                      <label className="custom-text-justify">
                        {stepOneLabels.town}
                      </label>
                      <span className='asterisk'>*</span>
                      <input
                        type="text"
                        autoComplete="no"
                        className={addressTownEmptyBool ? "form-control border-danger" : "form-control"}
                        maxLength={stepOneLabels.addressTownMaxLength}
                        onChange={(e) => setAddressTown(e.target.value.replace(/[,.]/g, ""))}
                        onBlur={(e) => addressTownOnBlur(e)}
                        onFocus={(e) => addressTownOnFocus(e)}
                        value={addressTown}
                        readOnly={invalidPhoneNumber || invalidSchemelimit || compRegBoolValMsg || invalidCompNameBool}
                        required
                      />
                    </div>
                    <div>
                      <label className="custom-text-justify">
                        {stepOneLabels.postcode}
                      </label>
                      <span className='asterisk'>*</span>
                      <input
                        type="text"
                        autoComplete="no"
                        className={addressPostCodeEmptyBool ? "form-control border-danger" : "form-control"}
                        maxLength={stepOneLabels.addressPostCodeMaxLength}
                        onChange={(e) => setAddressPostCode(e.target.value.replace(/[,.]/g, ""))}
                        onBlur={(e) => addressPostCodeOnBlur(e)}
                        onFocus={(e) => addressPostCodeOnFocus(e)}
                        value={addressPostCode}
                        readOnly={invalidPhoneNumber || invalidSchemelimit || compRegBoolValMsg || invalidCompNameBool}
                        required
                      />
                    </div>
                    <div>
                      <label className="custom-text-justify">
                        {stepOneLabels.country}
                      </label>
                      <span className='asterisk'>*</span>
                     
                      <select autoComplete="no"
                        className={addressCountryEmptyBool ? "form-control border-danger" : "form-control"}
                        onChange={(e) => addressCountryOnChange(e)}
                        onBlur={(e) => addressCountryOnBlur(e)}
                        onFocus={(e) => addressCountryOnFocus(e)}
                        value={addressCountry}
                        disabled={invalidPhoneNumber || invalidSchemelimit || compRegBoolValMsg || invalidCompNameBool}
                        required>
                        <option></option>
                        {stepOneLabels.countryArray.map((list, i) => {
                          return <option value={list.option} key={i}>{list.option}</option>
                        })}
                      </select>
                    </div>
                  </div>
                )}
                <div className='form-section'>
                  <div className='form-label'>
                    {stepOneLabels.ukNum}
                    <span className='asterisk'>*</span>
                    <span className='tooltip-icon'>
                      {/* <i className="fa fa-exclamation-circle"></i> */}
                      <FontAwesomeIcon icon={faCircleExclamation} />
                      <span className="tooltiptext">{stepOneLabels.ukNumTooltip}</span>
                    </span></div>
                  <input type="text" className={numofempEmptyBool ? "form-control border-danger" : "form-control"}
                    maxLength={stepOneLabels.compNameMaxLeg}
                    onChange={(e) => uknumOnChange(e)}
                    onBlur={() => numofempOnBlur()}
                    onFocus={() => setNumofempEmptyBool(false)}
                    autoComplete="no" value={numofemp} readOnly={invalidPhoneNumber || invalidSchemelimit || compRegBoolValMsg || invalidCompNameBool || addrInvalidBool} />
                </div>
                <div className='form-section'>
                  <div className='form-label'>{stepOneLabels.industry}<span className='asterisk'>*</span></div>
                  <select name="industry" id="industry" className={comptypeEmptyBool ? "form-control border-danger" : "form-control"}
                    onChange={(e) => setComptype(e.target.value)}
                    onBlur={() => { comptypeOnBlur() }}
                    onFocus={() => { setComptypeEmptyBool(false) }}
                    disabled={invalidPhoneNumber || invalidSchemelimit || compRegBoolValMsg || invalidCompNameBool || addrInvalidBool} value={comptype}>
                    <option value=""></option>

                    {industryList.map((list, i) =>
                      <option key={i} value={list}>{list}</option>
                    )}
                  </select>
                </div>
                <div className='form-section'>
                  <div className='form-label'>{stepOneLabels.telNum}<span className='asterisk'>*</span></div>
                  <input type="text" className={companyphoneEmptyBool ? "form-control border-danger" : "form-control"} autoComplete="no" maxLength={commonLabels.mobileNumberMaxLen}
                    onChange={(e) => phonenumberOnChange(e)} onBlur={(e) => { companyPhoneOnBlur(e) }} onFocus={(e) => { companyPhoneOnFocus(e) }} value={companyphone} readOnly={invalidSchemelimit || compRegBoolValMsg || invalidCompNameBool || addrInvalidBool} />
                </div>

                {invalidPhoneNumber && <label className='error-msg'>
                  {errorMessages.mobile}
                </label>}
                <div className='form-section'>
                  <div className='form-label'>{stepOneLabels.schemeLimit}<span className='asterisk'>*</span>
                    <span className='tooltip-icon'>
                      {/* <i className="fa fa-exclamation-circle"></i> */}
                      <FontAwesomeIcon icon={faCircleExclamation} />
                      <span className="tooltiptext">{stepOneLabels.schemelimitTooltip}</span>
                    </span></div>
                  <input type="text" className={schemelimitEmptyBool ? "form-control border-danger" : "form-control"} autoComplete="no"
                  maxLength={stepOneLabels.compNameMaxLeg}
                    onChange={(e) => schemelimitOnChange(e)} onBlur={(e) => { schemelimitOnBlur(e) }} onFocus={(e) => { schemelimitOnFocus(e) }} value={schemelimit} readOnly={invalidPhoneNumber || invalidSchemelimit || compRegBoolValMsg || invalidCompNameBool || addrInvalidBool} />
                </div>
                {invalidSchemelimit && <label className='error-msg'>
                  {validationMsg.schemeLimit}
                </label>}
                <div className='form-section'>
                  <div className='form-label'>{stepOneLabels.hearAbout}<span className='asterisk'>*</span></div>
                  <select name="hearaboutus" id="hearaboutus" className='form-control' onChange={(e) => setHearaboutus(e.target.value)} onBlur={(e) => fieldEmptyValidation()} disabled={invalidPhoneNumber || invalidSchemelimit || compRegBoolValMsg || invalidCompNameBool || addrInvalidBool} value={hearaboutus}>
                    <option value=""></option>
                    {hearaboutusList.map((list, i) => {
                      return <option value={list} key={i}>{list}</option>
                    })}
                  </select>
                </div>
                <button className='step1-next-button'
                  disabled={bool}
                  onClick={() => onClickStep1Next()}>{commonLabels.nextButton}
                  {/* <i className="fa fa-arrow-right"></i> */}
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
              <div className="col-sm form-right">
                <img src={cycleToWorkLogo} alt="cycleToWorkLogo" className='cycleToWorkLogo' />
                <div className='right-content-main-step1'>
                  <div className='right-content-header'>{stepOneLabels.content1}
                  </div>
                  <div className='right-content-text'>{stepOneLabels.content2}
                  </div>
                  <div className='row row-step1'>
                    <div className='col-sm-2 col-icon'>
                      <img src={icon1} alt="icon1" />
                    </div>
                    <div className='col-sm col-text'>{stepOneLabels.content3}
                    </div>
                  </div>
                  <div className='row row-step2'>
                    <div className='col-sm-2 col-icon'>
                      <img src={icon2} alt="icon2" />
                    </div>
                    <div className='col-sm col-text'>{stepOneLabels.content4}
                    </div>
                  </div>                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {networkPopup && <NetworkError setNetworkPopup={setNetworkPopup}/>}
    </>
  )
}

export default StepOne