import React, { useState, useContext, useEffect } from 'react';
import cycleToWorkLogo from '../assets/images/cycleToWorkLogo.jpg';
import icon3 from '../assets/images/icon3.svg';
import icon4 from '../assets/images/icon4.svg';
import { commonLabels, stepTwoLabels } from '../components/ScreenLabels';
import { tabLabels } from "../components/ScreenLabels";
import { Context } from '../components/Context';
import {faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactGA from "react-ga4";
const StepTwo = () => {
    const [context, setContext] = useContext(Context);
    const [currentTab, setCurrentTab] = useState(2);
    const [firstname, setFirstname] = useState(context.postStep2Data ? context.postStep2Data.firstname : "");
    const [surname, setSurname] = useState(context.postStep2Data ? context.postStep2Data.surname : "");
    const [jobtitle, setJobtitle] = useState(context.postStep2Data ? context.postStep2Data.jobtitle : "");
    const [telephone, setTelephone] = useState(context.postStep2Data ? context.postStep2Data.telephone : "");
    const [email, setEmail] = useState(context.postStep2Data ? context.postStep2Data.email : "");
    const [confirmemail, setConfirmemail] = useState(context.postStep2Data ? context.postStep2Data.confirmemail : "");
    const [invoiceemail, setInvoiceemail] = useState(context.postStep2Data ? context.postStep2Data.invoiceemail : "");
    const [confirminvoiceemail, setConfirmInvoiceemail] = useState(context.postStep2Data ? context.postStep2Data.confirminvoiceemail : "");
    const [firstnameEmptyBool, setFirstnameEmptyBool] = useState(false);
    const [surnameEmptyBool, setSurnameEmptyBool] = useState(false);
    const [jobtitleEmptyBool, setJobtitleEmptyBool] = useState(false);
    const [telephoneEmptyBool, setTelephoneEmptyBool] = useState(false);
    const [emailEmptyBool, setEmailEmptyBool] = useState(false);
    const [confirmemailEmptyBool, setConfirmemailEmptyBool] = useState(false);
    const [invoiceemailEmptyBool, setInvoiceemailEmptyBool] = useState(false);
    const [confirmInvoiceemailEmptyBool, setConfirmInvoiceemailEmptyBool] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [invalidConfirmEmail, setInvalidConfirmEmail] = useState(false);
    const [invalidInvoiceEmail, setInvalidInvoiceEmail] = useState(false);
    const [invalidConfirmInvoiceEmail, setInvalidConfirmInvoiceEmail] = useState(false);
    const [invalidTelephone, setInvalidTelephone] = useState(false);
    const [emailNotMatching, setEmailNotMatching] = useState(false)
    const [invoiceEmailNotMatching, setInvoiceEmailNotMatching] = useState(false)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
      }, []);

    const fieldEmptyValidation = () => {
        if (confirminvoiceemail.length) {
            firstname === "" ? setFirstnameEmptyBool(true) : setFirstnameEmptyBool(false);
            surname === "" ? setSurnameEmptyBool(true) : setSurnameEmptyBool(false);
            jobtitle === "" ? setJobtitleEmptyBool(true) : setJobtitleEmptyBool(false);
            telephone === "" ? setTelephoneEmptyBool(true) : setTelephoneEmptyBool(false);
            email === "" ? setEmailEmptyBool(true) : setEmailEmptyBool(false);
            confirmemail === "" ? setConfirmemailEmptyBool(true) : setConfirmemailEmptyBool(false);
            // invoiceemail === "" ? setInvoiceemailEmptyBool(true) : setInvoiceemailEmptyBool(false);
        } else {
            setFirstnameEmptyBool(false);
            setSurnameEmptyBool(false);
            setJobtitleEmptyBool(false);
            setTelephoneEmptyBool(false);
            setEmailEmptyBool(false);
            setConfirmemailEmptyBool(false);
            // setInvoiceemailEmptyBool(false);
        }
    }

    let bool = true;  


    if (firstname !== "" && surname !== "" && jobtitle !== "" && telephone !== "" && email !== "" && confirmemail !== "" && !confirmInvoiceemailEmptyBool && !invoiceemailEmptyBool
    && !invalidEmail && !invalidConfirmEmail && !invalidInvoiceEmail && !invalidConfirmInvoiceEmail && !invalidTelephone
    && !emailNotMatching && !invoiceEmailNotMatching
) {
    bool = false;
}

    const onClickStep2Next = () => {
        const postStep2Data = {
            firstname: firstname,
            surname: surname,
            jobtitle: jobtitle,
            telephone: telephone,
            email: email,
            confirmemail: confirmemail,
            invoiceemail: invoiceemail,
            confirminvoiceemail: confirminvoiceemail,
        }
        ReactGA.event({category:'Cycle2Work',action:'cycle2work_main_contact'});
        setContext({ ...context, step:'step3', postStep2Data });
    }

    const onClickStep2Back = () => {
        setContext({ ...context, step: "step1" });
    }

    const fnameOnChange = (e) => {
        let val = e.target.value.replace(/[^A-Za-z-'\s]/g, "");
        setFirstname(val);
    }

    const fnameOnBlur = () => {
        if (!firstname.length) {
            confirminvoiceemail.length ? setFirstnameEmptyBool(true) : setFirstnameEmptyBool(false)
        }
    }

    const fnameOnFocus = () => {
        setFirstnameEmptyBool(false);
    }

    const snameOnChange = (e) => {
        let val = e.target.value.replace(/[^A-Za-z-'\s]/g, "");
        setSurname(val);
    }

    const snameOnBlur = () => {
        if (!surname.length) {
            confirminvoiceemail.length ? setSurnameEmptyBool(true) : setSurnameEmptyBool(false)
        }
    }

    const snameOnFocus = () => {
        setSurnameEmptyBool(false)
    }

    const jobtitleOnChange = (e) => {
        let val = e.target.value;
        setJobtitle(val);
    }

    const jobTitleOnBlur = () => {
        if (!jobtitle.length) {
            confirminvoiceemail.length ? setJobtitleEmptyBool(true) : setJobtitleEmptyBool(false)
        }
    }

    const jobTitleOnFocus = () => {
        setJobtitleEmptyBool(false)
    }
    const telephoneOnChange = (e) => {
        let val = e.target.value.replace(/[^0-9]/g, "");
        setTelephone(val);
    }
    const telephoneOnBlur = (e) => {
        if (telephone.length > 0) {
            // telephone.length < 11 ? setInvalidTelephone(true) : setInvalidTelephone(false);
            if(telephone.length<commonLabels.mobileNumberMinLen || telephone.length >commonLabels.mobileNumberMaxLen){
                setInvalidTelephone(true)
            }else{
                setInvalidTelephone(false);
            }
        } else {
            confirminvoiceemail.length ? setTelephoneEmptyBool(true) : setTelephoneEmptyBool(false)
        }
    }

    const telephoneOnFocus = (e) => {
        setInvalidTelephone(false);
        setTelephoneEmptyBool(false)
    }
    const emailOnChange = (e) => {
        setEmail(e.target.value);
    }

    const confirmemailOnChange = (e) => {

        setConfirmemail(e.target.value);
    }

    const invoiceemailOnChange = (e) => {
        let val=e.target.value
        setInvoiceemail(val);
        if (val.length && confirminvoiceemail.length){

        }
    }

    const confirminvoiceemailOnChange = (e) => {
        setConfirmInvoiceemail(e.target.value);
    }

    const emailOnBlur = (e) => {
        if (email.length > 0) {
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
                setInvalidEmail(true);
            } else {
                setInvalidEmail(false);
            }
        } else {
            confirminvoiceemail.length ? setEmailEmptyBool(true) : setEmailEmptyBool(false)
        }

        if (email.length && confirmemail.length) {
            email !== confirmemail ? setEmailNotMatching(true) : setEmailNotMatching(false);
        }
    }

    const confirmemailOnBlur = (e) => {
        if (confirmemail.length > 0) {
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(confirmemail)) {
                setInvalidConfirmEmail(true);
            } else {
                setInvalidConfirmEmail(false);
            }
        } else {
            confirminvoiceemail.length ? setConfirmemailEmptyBool(true) : setConfirmemailEmptyBool(false)
        }
        if (confirmemail.length && email.length) {
            email !== confirmemail ? setEmailNotMatching(true) : setEmailNotMatching(false)
        }
    }

    const invoiceemailOnBlur = (e) => {
        if (invoiceemail.length) {
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(invoiceemail)) {
                setInvalidInvoiceEmail(true);
            } else {
                setInvalidInvoiceEmail(false);
            }
            if (!confirminvoiceemail.length){
                setConfirmInvoiceemailEmptyBool(true);
            }

        } else {
            if (confirminvoiceemail.length){
                setInvoiceEmailNotMatching(true);              
               
            }
            // confirminvoiceemail.length ? setInvoiceemailEmptyBool(true) : setInvoiceemailEmptyBool(false)
        }
        if (invoiceemail.length && confirminvoiceemail.length) {
            setInvoiceemailEmptyBool(false);
            setConfirmInvoiceemailEmptyBool(false);
            invoiceemail !== confirminvoiceemail ? setInvoiceEmailNotMatching(true) : setInvoiceEmailNotMatching(false)
        }else if (!invoiceemail.length && !confirminvoiceemail.length) {
            setInvoiceEmailNotMatching(false);
            setInvalidInvoiceEmail(false);
        }
    }


    const confirminvoiceemailOnBlur = (e) => {
        if (confirminvoiceemail.length) {
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(confirminvoiceemail)) {
                setInvalidConfirmInvoiceEmail(true);
            } else {
                setInvalidConfirmInvoiceEmail(false);
            }
            if (!invoiceemail.length) {
                setConfirmInvoiceemailEmptyBool(true);
            } 
        }else{
            if (invoiceemail.length)    {
                setInvoiceEmailNotMatching(true);
               
            }
        }
        if (confirminvoiceemail.length && invoiceemail.length) {
            setInvoiceemailEmptyBool(false);
            setConfirmInvoiceemailEmptyBool(false);
            invoiceemail !== confirminvoiceemail ? setInvoiceEmailNotMatching(true) : setInvoiceEmailNotMatching(false)
        }else if (!confirminvoiceemail.length && !invoiceemail.length) {
            setInvoiceEmailNotMatching(false);
            setInvalidConfirmInvoiceEmail(false);
        }
        fieldEmptyValidation();
    }

    const emailOnFocus = (e) => {
        setInvalidEmail(false);
        setEmailEmptyBool(false);
    }

    const confirmemailOnFocus = (e) => {
        setInvalidConfirmEmail(false);
        setConfirmemailEmptyBool(false)
    }

    const invoiceemailOnFocus = (e) => {
        setInvalidInvoiceEmail(false);
        setInvoiceemailEmptyBool(false);
        setInvoiceEmailNotMatching(false);
    }

    const confirminvoiceemailOnFocus = (e) => {
        setInvalidConfirmInvoiceEmail(false);
        setInvoiceEmailNotMatching(false);
        setConfirmInvoiceemailEmptyBool(false);
    }

    return (
        <>
            <div className='content'>
                <div className='tab-container'>
                    <div className='tabs'>
                        {tabLabels.map((tab, i) =>
                            <button style={{ cursor: "auto" }} key={i} id={tab.id} disabled={currentTab == `${tab.id}`}>{tab.title}</button>
                        )}
                    </div>
                    <div className="step-main-container">
                        <div className="row bg-image">
                            <div className="col-sm form-left">
                                <div>
                                    <div className="tab-header">{stepTwoLabels.header}</div>
                                    <div className='form-section'>
                                        <div className='form-label'>{stepTwoLabels.fname}<span className='asterisk'>*</span></div>
                                        <input type="text" className={firstnameEmptyBool ? "form-control border-danger" : "form-control"} autoComplete="no" maxLength={stepTwoLabels.fnameMaxLen} value={firstname}
                                            onChange={(e) => fnameOnChange(e)} onBlur={() => fnameOnBlur()} onFocus={() => fnameOnFocus()}
                                            readOnly={invalidEmail || invalidConfirmEmail || invalidInvoiceEmail || invalidConfirmInvoiceEmail || invalidTelephone || emailNotMatching || invoiceEmailNotMatching} 
                                            autoFocus
                                            />
                                    </div>
                                    <div className='form-section'>
                                        <div className='form-label'>{stepTwoLabels.sname}<span className='asterisk'>*</span></div>
                                        <input type="text" className={surnameEmptyBool ? "form-control border-danger" : "form-control"} autoComplete="no" maxLength={stepTwoLabels.fnameMaxLen}
                                            onChange={(e) => snameOnChange(e)} onBlur={() => snameOnBlur()} onFocus={() => snameOnFocus()}
                                            value={surname} readOnly={invalidEmail || invalidConfirmEmail || invalidInvoiceEmail || invalidConfirmInvoiceEmail || invalidTelephone || emailNotMatching || invoiceEmailNotMatching} />
                                    </div>
                                    <div className='form-section'>
                                        <div className='form-label'>{stepTwoLabels.jobTitle}<span className='asterisk'>*</span></div>
                                        <input type="text" className={jobtitleEmptyBool ? "form-control border-danger" : "form-control"} autoComplete="no"
                                            onChange={(e) => jobtitleOnChange(e)} onBlur={() => jobTitleOnBlur()} onFocus={() => jobTitleOnFocus()}
                                            value={jobtitle} readOnly={invalidEmail || invalidConfirmEmail || invalidInvoiceEmail || invalidConfirmInvoiceEmail || invalidTelephone || emailNotMatching || invoiceEmailNotMatching} />
                                    </div>
                                    <div className='form-section'>
                                        <div className='form-label'>{stepTwoLabels.phNum}<span className='asterisk'>*</span></div>
                                        <input type="text" className={telephoneEmptyBool ? "form-control border-danger" : "form-control"} autoComplete="no" maxLength={commonLabels.mobileNumberMaxLen}
                                            onChange={(e) => telephoneOnChange(e)} onBlur={(e) => { telephoneOnBlur(e) }} onFocus={(e) => { telephoneOnFocus(e) }}
                                            value={telephone} readOnly={invalidEmail || invalidConfirmEmail || invalidInvoiceEmail || invalidConfirmInvoiceEmail || emailNotMatching || invoiceEmailNotMatching} />
                                    </div>
                                    {invalidTelephone && <div className='error-msg'>{context.errorMessages.mobile}</div>}
                                    <div className='form-section'>
                                        <div className='form-label'>{stepTwoLabels.emailAdd}<span className='asterisk'>*</span></div>
                                        <input type="text" className={emailEmptyBool ? "form-control border-danger" : "form-control"} autoComplete="no" maxLength={commonLabels.emailMaxLen}
                                            onChange={(e) => emailOnChange(e)} onBlur={(e) => { emailOnBlur(e) }} onFocus={(e) => { emailOnFocus(e) }} value={email} readOnly={invalidConfirmEmail || invalidInvoiceEmail || invalidConfirmInvoiceEmail || invalidTelephone || invoiceEmailNotMatching} />
                                    </div>
                                    {invalidEmail && <div className='error-msg'>{context.errorMessages.email}</div>}
                                    <div className='form-section'>
                                        <div className='form-label'>{stepTwoLabels.confirmEmailAdd}<span className='asterisk'>*</span></div>
                                        <input type="text" className={confirmemailEmptyBool ? "form-control border-danger" : "form-control"} autoComplete="no" maxLength={commonLabels.emailMaxLen}
                                            onChange={(e) => confirmemailOnChange(e)} onBlur={(e) => { confirmemailOnBlur(e) }} onFocus={(e) => { confirmemailOnFocus(e) }} value={confirmemail} readOnly={invalidEmail || invalidInvoiceEmail || invalidConfirmInvoiceEmail || invalidTelephone || invoiceEmailNotMatching} />
                                    </div>
                                    {invalidConfirmEmail && <div className='error-msg'>{context.errorMessages.email}</div>}
                                    {emailNotMatching && <div className='error-msg'>{context.errorMessages.noMatchEmail}</div>}
                                    <div className='form-section'>
                                        <div className='form-label'>
                                            {stepTwoLabels.invoiceEmail}
                                            {/* <span className='asterisk'>*</span> */}
                                            <span className='tooltip-icon'>
                                                {/* <i className="fa fa-exclamation-circle"></i> */}
                                                <FontAwesomeIcon icon={faCircleExclamation} />
                                                <span className="tooltiptext">{stepTwoLabels.invoicemailTooltip}</span>
                                            </span>
                                        </div>
                                        <input type="text" 
                                        // className={invoiceemailEmptyBool ? "form-control border-danger" : "form-control"} 
                                        className="form-control"
                                        autoComplete="no" maxLength={commonLabels.emailMaxLen}
                                            onChange={(e) => invoiceemailOnChange(e)} onBlur={(e) => { invoiceemailOnBlur(e) }} onFocus={(e) => { invoiceemailOnFocus(e) }} value={invoiceemail} readOnly={invalidEmail || invalidConfirmEmail || invalidConfirmInvoiceEmail || invalidTelephone || emailNotMatching} />
                                    </div>
                                    {invalidInvoiceEmail && <div className='error-msg'>{context.errorMessages.email}</div>}
                                    <div className='form-section'>
                                        <div className='form-label'>{stepTwoLabels.confirmInvoiceEmail}
                                        {/* <span className='asterisk'>*</span> */}
                                        </div>
                                        <input type="text" className='form-control' autoComplete="no" maxLength={commonLabels.emailMaxLen}
                                            onChange={(e) => confirminvoiceemailOnChange(e)} onBlur={(e) => { confirminvoiceemailOnBlur(e) }} onFocus={(e) => { confirminvoiceemailOnFocus(e) }} value={confirminvoiceemail} readOnly={invalidEmail || invalidConfirmEmail || invalidInvoiceEmail || invalidTelephone || emailNotMatching} />
                                    </div>
                                </div>
                                {invalidConfirmInvoiceEmail && <div className='error-msg'>{context.errorMessages.email}</div>}
                                {invoiceEmailNotMatching && <div className='error-msg'>{context.errorMessages.noMatchEmail}</div>}
                                <div className=' d-flex mt-5 clearfix button-group'>
                                    <div>
                                        <button className='step2-back-button' onClick={() => { onClickStep2Back() }}>{commonLabels.backButton}</button>
                                    </div>
                                    <div className='ml-auto'>
                                        <button className='float-right step2-next-button' 
                                        disabled={bool}
                                         onClick={() => { onClickStep2Next() }}>{commonLabels.nextButton}</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm form-right">
                                <img src={cycleToWorkLogo} alt="cycleToWorkLogo" className='cycleToWorkLogo' />
                                <div className='right-content-main-step2'>
                                    <div className='right-content-header'>{stepTwoLabels.content1}
                                    </div>
                                    <div className='right-content-step2-text'>{stepTwoLabels.content2}
                                    </div>
                                    <div className='row row-step1'>
                                        <div className='col-sm-2 col-icon'>
                                            <img src={icon3} alt="icon1" />
                                        </div>
                                        <div className='col-sm col-text icon-text'>{stepTwoLabels.content3}</div>
                                    </div>
                                    <div className='row row-step2 mt-3 mb-3'>
                                        <div className='col-sm-2 col-icon'>
                                            <img src={icon4} alt="icon2" />
                                        </div>
                                        <div className='col-sm col-text icon-text'>{stepTwoLabels.content4}</div>
                                    </div>
                                    <div className='right-content-step2-text'>{stepTwoLabels.content5}</div>
                                    <div className='right-content-step2-text' style={{fontSize:'14px'}}>{stepTwoLabels.content6}</div>
                                    <div className='right-content-step2-text' style={{fontSize:'14px'}}>{stepTwoLabels.content7}</div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StepTwo